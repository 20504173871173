import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
//import Form from "./Form";
import { FiNavigation, FiPhoneCall } from 'react-icons/fi';
import { AiFillClockCircle } from 'react-icons/ai'
import { FaCalendarAlt } from 'react-icons/fa'


function ContactInfo() {
  const { rpdata } = useContext(GlobalDataContext);

  const contentInfo = [
    {
      title: 'Location',
      subTitle: rpdata?.dbPrincipal?.location?.[0].address,
      icon: <FiNavigation fontSize={45} color={'#1d1d1d'} />,
      links: '',
    },
    {
      title: 'Phone',
      subTitle: rpdata?.dbPrincipal?.phones?.[0].phone,
      icon: <FiPhoneCall fontSize={45} color={'#1d1d1d'} />,
      links: `tel:+1${rpdata?.dbPrincipal?.phones?.[0].phone}`,
    },
    {
      title: 'Workdays',
      subTitle: rpdata?.dbPrincipal?.workdays?.[0].day,
      icon: <FaCalendarAlt fontSize={45} color={'#1d1d1d'} />,
      links: '',
    },
    {
      title: 'Work Hours',
      subTitle: rpdata?.dbPrincipal?.workHours?.[0].hour,
      icon: <AiFillClockCircle fontSize={45} color={'#1d1d1d'} />,
      links: '',
    },
  ]

  return (
    <>
      <div className="w-full md:px-14 pt-8">
        <h2 className="text-center">Get In Touch!</h2>
        <div className="w-4/5 mx-auto pb-8 grid md:grid-cols-4 grid-cols-1 gap-5">
          {contentInfo.map((items, index) => {
            return (
              <div className="px-5 py-8 shadow-lg" key={index}>
                <div className="flex">
                  <div className="bg-[#1d1d1d] h-[1px] w-[20%] self-center mr-3"></div>
                  <h5>{items.title}</h5>
                </div>
                {items.links.length > 1 ? (
                  <a href={`${items.links}`}>
                    <div className="flex space-x-4">
                      {items.icon}
                      <h6>{items.subTitle}</h6>
                    </div>
                  </a>
                ) : (
                  <div className="flex space-x-4">
                    {items.icon}
                    <h6>{items.subTitle}</h6>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="flex justify-center mx-auto w-full">
          <div className=" w-4/5">
            <h3 className="font-bold text-center">Social Media</h3>
            <ul className="flex justify-center space-x-7">
              {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                return (
                  <li
                    key={index}
                    className=" transition-all ease-in-out duration-300 color-2"
                  >
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className={`fab fa-${item.icon} text-[25px]`}
                        aria-hidden="true"
                      />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
        <div className="espacif flex justify-center mx-auto w-full bg-center bg-cover" style={{ backgroundImage: `url("${rpdata?.stock?.[4]}")` }} >
          <iframe
              src="https://link.automatrixai.com/widget/form/iV5h88erxoxCWQpAQyhn"
              className="StyleForm"
              id="inline-iV5h88erxoxCWQpAQyhn"
              data-layout="{'id':'INLINE'}"
              data-trigger-type="alwaysShow"
              data-trigger-value=""
              data-activation-type="alwaysActivated"
              data-activation-value=""
              data-deactivation-type="neverDeactivate"
              data-deactivation-value=""
              data-form-name="Request A FREE Estimate"
              /* data-height="1538" */
              data-layout-iframe-id="inline-iV5h88erxoxCWQpAQyhn"
              data-form-id="iV5h88erxoxCWQpAQyhn"
              title="Request A FREE Estimate"
            ></iframe>
          </div>

     {/*  <div className="w-full flex justify-center">
        <div className="flex w-[1100px] py-24 md:flex-row flex-col text-center md:text-start">
          <div
            className="
              md:w-[50%]
              w-full
              md:px-14
              px-2
              bg-center
              bg-cover
            "
            style={{ backgroundImage: `url("${rpdata?.stock?.[4]}")` }}
          ></div>
          <div className="md:w-[50%] w-full md:px-14 px-2">
            <h3>Send Us A Message</h3>
             <div class="elfsight-app-249038c5-7f9a-4d68-ae68-db74b419f8e6"></div>
 

          </div>
        </div>
      </div> */}
    </>
  );
}

export default ContactInfo;
